import React from 'react';
import { Link } from 'gatsby';
import SEO from '../components/seo';
import tabitha from '../assets/tabitha-orangeyred.svg';
import catBack from '../assets/cat-back@2x.png';
import '../styles/404.scss';

const NotFoundPage = () => (
  <div className="tio--bg-blackish vh-100 text-center tio--pt-2">
    <SEO title="Page Not Found" />
    <img src={tabitha} alt="Tabitha" />
    <div className="d-flex flex-column align-items-center tio--page-not-found-content">
      <img src={catBack} alt="Tabitha cat" className="tio--cat-back" />
      <h1 className="tio--heading-large">Oops!</h1>
      <h2 className="tio--body-text-1 tio--text-greyish">
        This page doesn't exist yet. <br />
        Try starting from{' '}
        <Link className="tio--body-text-1 tio--text-greyish" to="/">
          here
        </Link>
        .
      </h2>
    </div>
  </div>
);

export default NotFoundPage;
